import React from 'react'
import { enquireScreen } from 'enquire-js'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'

import Layout from '../components/layout'

import '../components/antd/less/antMotionStyle.less'
import SEO from '../components/seo'

let isMobile
enquireScreen(b => {
  isMobile = b
})

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { newItem } = strapi

    return (
      <Layout hasBg={true}>
        <SEO title='新闻动态' />
        <div className='news-detail'>
          <h1>{newItem.title}</h1>
          <p className='description'>{newItem.short_description}</p>
          <p className='publish-time'>发布时间：{newItem.publish_date}</p>
          <p className='tags'>
            {(newItem.tags || []).map(tag => (
              <span key={tag.id}>{tag.key}</span>
            ))}
          </p>
          <p className='main-img'>
            <img
              src={newItem.img.url.replace(
                '/uploads',
                `${process.env.STRAPI_URL}/uploads`
              )}
              alt='新闻主图'
            />
          </p>
          <Markdown
            source={newItem.content.replace(
              '/uploads',
              `${process.env.STRAPI_URL}/uploads`
            )}
            escapeHtml={false}
          />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query($newId: ID!) {
    strapi {
      newItem: new(id: $newId) {
        id
        title
        publish_date
        short_description
        link
        content
        tags {
          id
          key
        }
        img {
          url
        }
      }
    }
  }
`
